import { CheckCircleTwoTone, CloseCircleTwoTone, ExclamationCircleTwoTone, WarningOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Modal,
  Popover,
  Row,
  Select,
  Spin,
  Table,
  Tag,
  Tooltip,
  notification,
} from "antd";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { connect } from "react-redux";
import scrollIntoView from "scroll-into-view";
import { DEFAULT_PAGINATION_LIMIT } from "../constants/globals";
import { getMerchantNames, getOrderById, searchOrders, setReady } from "../util/APIUtils";
import { ORDER_STATUSES, mapStatusToColour, mapStatusToShortName } from "../util/Helpers";
import ChangeWindow from "./ChangeWindow";
import "./Dashboard.css";
import DeliveryTypeFilter from "./DeliveryTypeFilter/DeliveryTypeFilter";
import { ExportOrders } from "./ExportOrders";
import NullSafeText from "./NullSafeText";
import OrderListDetails from "./OrderListDetails";
import { PrintLabels } from "./PrintLabels";
const { Option } = Select;

const { RangePicker } = DatePicker;

export class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.notes = React.createRef();
    this.externalNotes = React.createRef();
    this.state = {
      orders: null,
      selectedOrder: null,
      loading: false,
      hasMore: true,
      selectedOrders: [],
      merchants: [],
      selectedRowKeys: [],
      showModal: false,
      loadingTimeWindowChange: false,
      formValues: {
        fromDate: null,
        fromTime: null,
        toDate: null,
        toTime: null,
        category: null,
        checked: false,
      },
      checkDisabled: false,
      showForm: false,
      errorList: [],
      successList: [],
      status: "active",
      warningList: [],
      readyPopoverVisible: false,
      loadingToggleReadyChange: false,
      loadingMerchants: false,
      currentOrderParams: null,
      limit: DEFAULT_PAGINATION_LIMIT,
      showPickupReadyModal: false,
      orderStatus: localStorage.getItem("orderStatus"),
    };
  }

  async componentDidMount() {
    this.setState({ loadingMerchants: true });
    let getMerchantsPromise = getMerchantNames();
    try {
      let merchants = await getMerchantsPromise;
      this.setState({
        merchants: merchants,
      });
    } catch (err) {}
    this.setState({ loadingMerchants: false });
  }

  onChange = (e) => {
    if (e.target.name === "id" && e.target.value) this.setState({ [e.target.name]: e.target.value.trim() });
    else this.setState({ [e.target.name]: e.target.value });
  };

  scrollToSelected = () => {
    scrollIntoView(document.querySelector(".selected-order"), {
      align: {
        top: 0,
      },
      cancellable: false,
    });
  };

  mapIdToMerchant = (merchantId) => {
    let merchant = this.state.merchants.find((obj) => {
      return obj.id === merchantId;
    });
    if (merchant != null) {
      return merchant.name;
    } else {
      return "";
    }
  };

  handleMerchantChange = (value) => {
    this.setState({
      merchantId: value,
    });
  };

  handleStatusChange = (value) => {
    let v = value;
    if (value === "ACTIVE") v = "DELIVERY_EN_ROUTE";
    if (!v) {
      localStorage.removeItem("orderStatus");
    } else {
      localStorage.setItem("orderStatus", v);
    }
    this.setState({
      orderStatus: v,
    });
  };

  handleDeliveryTypeChange = (value) => {
    this.setState({
      deliveryType: value,
    });
  };

  search = async () => {
    if (!this.state.merchantId && !this.state.orderStatus && !this.state.id) {
      const args = {
        message: "Error!",
        description: "One of Order Status or Merchant is required to search.",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      return notification.open(args);
    }
    this.setState({ orders: null });
    this.setState({ loading: true });
    this.setState({ hasMore: true });
    this.setState({ selectedRowKeys: [] });
    this.setState({ selectedRows: [] });
    this.setState({
      currentOrderParams: {
        id: this.state.id,
        refNumber: this.state.refNumber,
        merchantId: this.state.merchantId,
        orderStatus: this.state.orderStatus,
        deliveryType: this.state.deliveryType,
        createdAtStart: this.state.createdAtStart,
        createdAtEnd: this.state.createdAtEnd,
        completeAfter: this.state.completeAfter,
        completeBefore: this.state.completeBefore,
        lastEvaluatedKey: undefined,
        createdAt: undefined,
      },
    });
    if (this.state.id != null && this.state.id.length > 0) {
      try {
        let getOrderByIdPromise = getOrderById(this.state.id);
        let orders = await getOrderByIdPromise;
        this.setState({ orders: [orders], loading: false, hasMore: false });
      } catch (err) {
        this.setState({
          orders: null,
          loading: false,
          hasMore: false,
        });
      }
      return;
    }

    const promise = searchOrders({
      id: this.state.id,
      refNumber: this.state.refNumber,
      merchantId: this.state.merchantId,
      orderStatus: this.state.orderStatus,
      deliveryType: this.state.deliveryType,
      createdAtStart: this.state.createdAtStart,
      createdAtEnd: this.state.createdAtEnd,
      completeAfter: this.state.completeAfter,
      completeBefore: this.state.completeBefore,
      lastEvaluatedKey: this.state.lastEvaluatedKey,
      createdAt: this.state.createdAt,
      limit: this.state.limit,
    });
    try {
      let response = await promise;
      this.setState({ orders: response.orders, loading: false });
      this.setState((prevState) => ({
        hasMore: !!response?.next?.lastEvaluatedKey,
        currentOrderParams: {
          ...prevState.currentOrderParams,
          lastEvaluatedKey: response?.next?.lastEvaluatedKey,
          createdAt: response?.next?.createdAt,
        },
      }));
    } catch (err) {
      this.setState({
        orders: null,
        loading: false,
      });
    }
  };

  refreshOrders = async () => {
    await this.search();
  };

  refreshOrdersFromEdit = async () => {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await delay(3000);
    await this.search();
  };

  handleCreatedRangeChange = (date, dateString) => {
    if (date == null) {
      if (this.state.createdAtStart != null || this.state.createdAtEnd != null) {
        this.setState({
          createdAtStart: null,
          createdAtEnd: null,
        });
      }
    } else {
      if (date.length > 0) {
        let createdAtStart = date[0]
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .unix();
        let createdAtEnd = date[1]
          .set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 59,
          })
          .unix();
        this.setState({
          createdAtStart: createdAtStart,
          createdAtEnd: createdAtEnd,
        });
      }
    }
  };

  handleCompleteRangeChange = (date, dateString) => {
    if (date == null) {
      if (this.state.completeAfter != null || this.state.completeBefore != null) {
        this.setState({
          completeAfter: null,
          completeBefore: null,
        });
      }
    } else {
      if (date.length > 0) {
        let completeAfter = date[0]
          .set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })
          .valueOf();
        let completeBefore = date[1]
          .set({
            hour: 23,
            minute: 59,
            second: 59,
            millisecond: 59,
          })
          .valueOf();
        this.setState({
          completeAfter: completeAfter,
          completeBefore: completeBefore,
        });
      }
    }
  };

  handleReadyPopoverVisibleChange = (visible) => {
    if (this.state.selectedRowKeys.length > 0) {
      this.setState({ readyPopoverVisible: visible });
    }
  };

  handleReady = () => {
    if (this.state.selectedRowKeys.length === 0) {
      const args = {
        message: "Error!",
        description: "Please select at least one order.",
        duration: 5,
        type: "error",
        placement: "topRight",
      };
      notification.open(args);
      return;
    }

    this.setState({ loadingToggleReadyChange: true });

    const finalList = this.state.selectedRows
      .filter((e) => e.orderStatus === "GEOCODED")
      .map((x) => {
        return { id: x.id, orderStatus: x.orderStatus };
      });
    const unprocessedList = this.state.selectedRows
      .filter((e) => e.orderStatus !== "GEOCODED")
      .map((x) => {
        return {
          id: x.id,
          orderStatus: x.orderStatus,
          message: "Not processed because order status is not Gecoded",
          result: "unprocessed",
        };
      });

    const patchReadyAll = async () => {
      const newErrorList = [];
      const successList = [];
      for (const order of finalList) {
        try {
          await setReady(order.id);
          successList.push({
            ...order,
            orderStatus: "PICKUP_READY",
            message: "success",
            result: "success",
          });
        } catch (e) {
          newErrorList.push({ ...order, message: e.message, result: "error" });
        }
      }

      const results = successList.concat(newErrorList).concat(unprocessedList);
      this.setState({
        results: results,
        showPickupReadyModal: true,
        loadingToggleReadyChange: false,
        readyPopoverVisible: false,
      });

      for (const order of successList) {
        let list = this.state.orders;
        let foundIndex = list.findIndex((x) => x.id == order.id);
        if (foundIndex > -1) {
          if (list[foundIndex].orderStatus === "GEOCODED") {
            list[foundIndex].orderStatus = "PICKUP_READY";
            list[foundIndex].pickupReadyAt = Date.now();
          }
          this.setState({ orders: list });
        }
      }
    };

    this.setState({ loadingTimeWindowChange: true });
    patchReadyAll();
  };

  handleInfiniteOnLoad = async () => {
    if (this.state.hasMore) {
      this.setState({ loading: true });
      const promise = searchOrders({
        id: this.state.currentOrderParams.id,
        refNumber: this.state.currentOrderParams.refNumber,
        merchantId: this.state.currentOrderParams.merchantId,
        orderStatus: this.state.currentOrderParams.orderStatus,
        deliveryType: this.state.currentOrderParams.deliveryType,
        createdAtStart: this.state.currentOrderParams.createdAtStart,
        createdAtEnd: this.state.currentOrderParams.createdAtEnd,
        completeAfter: this.state.currentOrderParams.completeAfter,
        completeBefore: this.state.currentOrderParams.completeBefore,
        lastEvaluatedKey: this.state.currentOrderParams.lastEvaluatedKey,
        createdAt: this.state.currentOrderParams.createdAt,
        limit: this.state.limit,
      });
      try {
        let response = await promise;
        let newOrders = this.state.orders.concat(response.orders);
        this.setState({ orders: newOrders, loading: false });
        this.setState((prevState) => ({
          hasMore: !!response?.next?.lastEvaluatedKey,
          currentOrderParams: {
            ...prevState.currentOrderParams,
            lastEvaluatedKey: response?.next?.lastEvaluatedKey,
            createdAt: response?.next?.createdAt,
          },
        }));
      } catch (err) {
        this.setState({
          orders: null,
          loading: false,
        });
      }
    }
  };

  render() {
    const dateFormat = "MM/DD/YYYY";
    let merchantOptions = [];
    for (let merchant of this.state.merchants) {
      merchantOptions.push(
        <Option value={merchant.id} label={merchant.name}>
          {merchant.name}
        </Option>,
      );
    }
    let statusOptions = [];
    for (let status of ORDER_STATUSES) {
      statusOptions.push(
        <Option value={status} label={status}>
          {status}
        </Option>,
      );
    }
    const addNewlines = (str) => {
      var result = "";
      while (str.length > 0) {
        result += str.substring(0, 20) + "\n";
        str = str.substring(20);
      }
      return result;
    };

    const generatePackagesToolTip = (pickupReadyAt) => {
      return (
        <>
          {new Date(pickupReadyAt * 1000).toDateString()} {new Date(pickupReadyAt * 1000).toLocaleTimeString()}
        </>
      );
    };

    const columns = [
      {
        title: "Recipient",
        dataIndex: "recipient.name",
        key: "name",
        render: (text, record) => (
          <>
            {record.merchantDisplayName ? (
              <>
                <Tag color="magenta" style={{ fontSize: "7px" }}>
                  {record.merchantDisplayName}
                </Tag>
                <br />
              </>
            ) : (
              ""
            )}
            <NullSafeText text={record?.recipient?.name} />
            <br />
            <NullSafeText text={record?.recipient?.phone} />
          </>
        ),
      },
      {
        title: "Address",
        dataIndex: "recipientAddress.street",
        key: "address",
        render: (text, record) => {
          if (
            record.orderStatus != "GEOCODEDFAILED" &&
            record.orderStatus != "CREATED" &&
            record.recipientAddress != null
          ) {
            return `${record.recipientAddress.number} ${record.recipientAddress.street}\n${record.recipientAddress.city}, ${record.recipientAddress.province} ${record.recipientAddress.postalCode}`;
          }
          return "";
        },
      },
      {
        title: "Placed On",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (date) => {
          const isUnix = Number.isInteger(date);
          const formattedDate = isUnix ? date : moment(date).unix();

          return `${moment(formattedDate * 1000).format("ddd, MMM D, YYYY, h:mm a")}`;
        },
      },
      {
        title: "Status",
        dataIndex: "orderStatus",
        key: "orderStatus",
        width: "25%",
        render: (text, record) => {
          if (record.orderStatus !== "PICKUP_READY") {
            return (
              <>
                <Row>
                  <Tag color={mapStatusToColour(record.orderStatus)} key={record.orderStatus}>
                    {mapStatusToShortName(record.orderStatus)}
                  </Tag>
                </Row>
                {record.pickupReadyAt != null ? (
                  <>
                    <Row style={{ marginTop: 5 }}></Row>
                    <Row>
                      <Tooltip title={generatePackagesToolTip(record.pickupReadyAt)}>
                        <Tag color={mapStatusToColour("PICKUP_READY")} key={"PICKUP_READY"}>
                          {mapStatusToShortName("PICKUP_READY")}
                        </Tag>
                      </Tooltip>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </>
            );
          } else {
            if (record.pickupReadyAt != null) {
              return (
                <>
                  {record.pickupReadyAt != null ? (
                    <Tooltip title={generatePackagesToolTip(record.pickupReadyAt)}>
                      <Tag color={mapStatusToColour(record.orderStatus)} key={record.orderStatus}>
                        {mapStatusToShortName(record.orderStatus)}
                      </Tag>
                    </Tooltip>
                  ) : (
                    <Tag color={mapStatusToColour(record.orderStatus)} key={record.orderStatus}>
                      {mapStatusToShortName(record.orderStatus)}
                    </Tag>
                  )}
                </>
              );
            }
          }
        },
      },
      {
        title: "Merchant",
        dataIndex: "merchantId",
        key: "merchantId",
        width: "25%",
        render: (text, record) =>
          typeof record.merchantId === "undefined" ? "" : addNewlines(this.mapIdToMerchant(record.merchantId)),
      },
      {
        title: "Ref #",
        dataIndex: "refNumber",
        key: "refNumber",
        width: "25%",
        render: (text, record) => (record.refNumber == null ? "" : addNewlines(record.refNumber)),
      },
      {
        title: "Pickup Ready",
        dataIndex: "pickupReadyAt",
        key: "pickupReadyAt",
        filters: [
          { text: "PICKUP READY", value: "PICKUP_READY" },
          { text: "NOT PICKUP READY", value: "NOT_PICKUP_READY" },
        ],
        onFilter: (value, record) => {
          if (value === "PICKUP_READY") return record.pickupReadyAt != null;
          else return record.pickupReadyAt == null;
        },
        render: (text, record) => {
          if (record.pickupReadyAt != null) {
            let myPickupReadyAt = record.pickupReadyAt;
            if (myPickupReadyAt.toString().length === 10) myPickupReadyAt = myPickupReadyAt * 1000;
            return (
              <>
                {new Date(myPickupReadyAt).toDateString()} {new Date(myPickupReadyAt).toLocaleTimeString()}
              </>
            );
          } else {
            return <>Not Pickup Ready</>;
          }
        },
      },
    ];
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys: selectedRowKeys });
        this.setState({ selectedRows: selectedRows });
        this.setState({
          warningList: selectedRows.filter(
            (e) =>
              e.orderStatus === "DELIVERY_EN_ROUTE" ||
              e.orderStatus === "DELIVERY_COMPLETED" ||
              e.orderStatus === "DELIVERY_FAILED",
          ),
        });
      },
      selectedRowKeys: this.state.selectedRowKeys,
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User", // Column configuration not to be checked
        name: record.name,
      }),
    };
    const errorColumns = [
      {
        title: "Recipient",
        dataIndex: "recipient.name",
        key: "name",
        render: (text, record) => (
          <>
            {record.merchantDisplayName ? (
              <>
                <Tag color="magenta" style={{ fontSize: "7px" }}>
                  {record.merchantDisplayName}
                </Tag>
                <br />
              </>
            ) : (
              ""
            )}
            <NullSafeText text={record?.recipient?.name} />
            <br />
            <NullSafeText text={record?.recipient?.phone} />
          </>
        ),
      },
      {
        title: "Merchant",
        dataIndex: "merchantId",
        key: "merchantId",
        width: "25%",
        render: (text, record) =>
          record.merchantId == null ? "" : addNewlines(this.mapIdToMerchant(record.merchantId)),
      },
      {
        title: "Status",
        dataIndex: "orderStatus",
        key: "orderStatus",
        width: "25%",
        render: (text, record) => {
          if (record.orderStatus !== "PICKUP_READY") {
            return (
              <>
                <Row>
                  <Tag color={mapStatusToColour(record.orderStatus)} key={record.orderStatus}>
                    {mapStatusToShortName(record.orderStatus)}
                  </Tag>
                </Row>
                {record.pickupReadyAt != null ? (
                  <>
                    <Row style={{ marginTop: 5 }}></Row>
                    <Row>
                      <Tooltip title={generatePackagesToolTip(record.pickupReadyAt)}>
                        <Tag color={mapStatusToColour("PICKUP_READY")} key={"PICKUP_READY"}>
                          {mapStatusToShortName("PICKUP_READY")}
                        </Tag>
                      </Tooltip>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </>
            );
          } else {
            if (record.pickupReadyAt != null) {
              return (
                <>
                  {record.pickupReadyAt != null ? (
                    <Tooltip title={generatePackagesToolTip(record.pickupReadyAt)}>
                      <Tag color={mapStatusToColour(record.orderStatus)} key={record.orderStatus}>
                        {mapStatusToShortName(record.orderStatus)}
                      </Tag>
                    </Tooltip>
                  ) : (
                    <Tag color={mapStatusToColour(record.orderStatus)} key={record.orderStatus}>
                      {mapStatusToShortName(record.orderStatus)}
                    </Tag>
                  )}
                </>
              );
            }
          }
        },
      },
      {
        title: "Ref #",
        dataIndex: "refNumber",
        key: "refNumber",
        render: (text, record) => (record.refNumber == null ? "" : addNewlines(record.refNumber)),
      },
    ];

    const handleOnOperationComplete = (completedList, body) => {
      let list = this.state.orders;
      for (const orderId of completedList) {
        let foundIndex = list.findIndex((x) => x.id == orderId);
        if (foundIndex > -1) {
          let myTimeChangeEvents = list[foundIndex].timeChangeEvents;
          let newEvent = {
            time: Date.now(),
            initialCompleteAfter: list[foundIndex].completeAfter,
            initialCompleteBefore: list[foundIndex].completeBefore,
            newCompleteAfter: body.completeAfter,
            newCompleteBefore: body.completeBefore,
            changedBy: this.props.user,
            category: body.category,
          };

          if (body.notes != null && body.notes != "") newEvent.notes = body.notes;
          if (body.externalNotes != null) newEvent.externalNotes = body.externalNotes;
          if (body.showExternalNotes != null) newEvent.showExternalNotes = body.showExternalNotes;

          if (myTimeChangeEvents == null) list[foundIndex].timeChangeEvents = [newEvent];
          else {
            myTimeChangeEvents.push(newEvent);
            list[foundIndex].timeChangeEvents = myTimeChangeEvents;
          }
          list[foundIndex].completeAfter = body.completeAfter;
          list[foundIndex].completeBefore = body.completeBefore;
        }
      }
      this.setState({ orders: list });
    };

    const readyPopoverContent = (
      <Col>
        <Row gutter={16} style={{ marginBottom: "1vh" }}>
          <Col>
            <p>
              Clicking "Set Ready" will set <strong>Geocoded orders to Pickup Ready</strong>. Orders in other statuses
              will not be processed.
              <br />
              <strong>This will only apply to selected orders.</strong>
              <br />
              Are you sure you want to do this for {this.state.selectedRowKeys.length} orders?
            </p>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={5}>
            <Button type={"primary"} onClick={this.handleReady} loading={this.state.loadingToggleReadyChange}>
              Set Ready
            </Button>
          </Col>
          <Col span={5}>
            <Button
              loading={this.state.loadingToggleReadyChange}
              onClick={() => this.setState({ readyPopoverVisible: false })}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Col>
    );

    const mapResultToIcon = (result) => {
      switch (result) {
        case "success":
          return <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 20 }} />;
        case "error":
          return <CloseCircleTwoTone twoToneColor="red" style={{ fontSize: 20 }} />;
        case "unprocessed":
        default:
          return <ExclamationCircleTwoTone twoToneColor="orange" style={{ fontSize: 20 }} />;
      }
    };

    const pickupReadyColumns = [
      {
        title: "Result",
        dataIndex: "result",
        key: "result",
        render: (text, record) => mapResultToIcon(record.result),
      },
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
        render: (text, record) => record.id,
      },
      {
        title: "status",
        dataIndex: "status",
        key: "id",
        render: (text, record) => (
          <Tag color={mapStatusToColour(record.orderStatus)} key={record.orderStatus}>
            {mapStatusToShortName(record.orderStatus)}
          </Tag>
        ),
      },
      {
        title: "Message",
        dataIndex: "message",
        key: "message",
        width: "50%",
        render: (text, record) => record.message,
      },
    ];
    return (
      <div className={"dashboard-container"}>
        <Modal
          title="Pickup Ready Results"
          width="50%"
          closable={true}
          centered={true}
          visible={this.state.showPickupReadyModal}
          icon={<WarningOutlined />}
          cancelButtonProps={{ style: { display: "none" } }}
          onCancel={() => this.setState({ showPickupReadyModal: false })}
          onOk={() => this.setState({ showPickupReadyModal: false })}
        >
          <Table dataSource={this.state.results} columns={pickupReadyColumns} pagination={false} />
        </Modal>
        <Row gutter={16} className={"search-row"}>
          <Col span={4}>
            <Input
              style={{ width: "100%", height: "100%" }}
              placeholder="id"
              name="id"
              disabled={this.state.loading}
              onChange={this.onChange}
            />
          </Col>
          <Col span={4}>
            <Input
              style={{ width: "100%", height: "100%" }}
              placeholder="Ref Number"
              name="refNumber"
              disabled={this.state.loading}
              onChange={this.onChange}
            />
          </Col>
          <Col span={4}>
            <Select
              showSearch
              allowClear
              style={{ width: "100%", height: "100%" }}
              placeholder="Merchant"
              optionLabelProp="label"
              onChange={this.handleMerchantChange}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {merchantOptions}
            </Select>
          </Col>
          <Col span={4}>
            <Select
              showSearch
              defaultValue={this.state.orderStatus}
              allowClear
              style={{ width: "100%", height: "100%" }}
              placeholder="Status"
              optionLabelProp="label"
              onChange={this.handleStatusChange}
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {statusOptions}
            </Select>
          </Col>
          {this.props.isHeavyParcelConsolidationV1 && (
            <Col span={4}>
              <DeliveryTypeFilter onChange={this.handleDeliveryTypeChange} />
            </Col>
          )}
          <Col span={2}>
            <Button type={"primary"} onClick={this.search}>
              {" "}
              Search{" "}
            </Button>
          </Col>
        </Row>
        <Row gutter={16} className={"search-row"}>
          <Col span={10}>
            <RangePicker
              showClear
              style={{ width: "100%", height: "100%" }}
              ranges={{
                Today: [moment(), moment()],
                "This Month": [moment().startOf("month"), moment().endOf("month")],
                "Last Month": [
                  moment().subtract(1, "month").startOf("month"),
                  moment().subtract(1, "month").endOf("month"),
                ],
                "This Week": [moment().startOf("week"), moment().endOf("week")],
                "Last Week": [moment().subtract(1, "week").startOf("week"), moment().subtract(1, "week").endOf("week")],
              }}
              placeholder={["Created At Start", "Created At End "]}
              onChange={this.handleCreatedRangeChange}
              format={dateFormat}
              disabled={this.props.isLoading}
            />
          </Col>
          <Col span={10}>
            <RangePicker
              showClear
              style={{ width: "100%", height: "100%" }}
              ranges={{
                Today: [moment(), moment()],
                "This Month": [moment().startOf("month"), moment().endOf("month")],
                "Last Month": [
                  moment().subtract(1, "month").startOf("month"),
                  moment().subtract(1, "month").endOf("month"),
                ],
                "This Week": [moment().startOf("week"), moment().endOf("week")],
                "Last Week": [moment().subtract(1, "week").startOf("week"), moment().subtract(1, "week").endOf("week")],
              }}
              placeholder={["Deliv. Window Start", "Deliv. Window End"]}
              onChange={this.handleCompleteRangeChange}
              format={dateFormat}
              disabled={this.props.isLoading}
            />
          </Col>
        </Row>

        <Row gutter={16} className={"search-row"}>
          {this.props.roles && (this.props.roles.includes("super_admin") || this.props.roles.includes("admin")) && (
            <Col span={4}>
              <ChangeWindow
                showModal={this.state.showModal}
                selectedRowKeys={this.state.selectedRowKeys}
                selectedRows={this.state.selectedRows}
                warningList={this.state.warningList}
                merchants={this.state.merchants}
                orders={this.state.orders}
                errorColumns={errorColumns}
                onOperationComplete={handleOnOperationComplete}
              />
            </Col>
          )}
          <Col span={4}>
            <Popover
              content={readyPopoverContent}
              placement="bottom"
              trigger="click"
              visible={this.state.readyPopoverVisible}
              onVisibleChange={this.handleReadyPopoverVisibleChange}
            >
              <div style={{ width: "100%" }}>
                <Button
                  type="primary"
                  disabled={this.state.selectedRowKeys.length <= 0}
                  loading={this.state.loadingToggleReadyChange}
                  style={{ width: "100%" }}
                >
                  Ready
                </Button>
              </div>
            </Popover>
          </Col>
          <Col span={4}>
            <ExportOrders
              selectedRowKeys={this.state.selectedRowKeys}
              selectedRows={this.state.selectedRows}
              merchants={this.state.merchants}
            />
          </Col>
          <Col span={4}>
            <PrintLabels
              selectedRowKeys={this.state.selectedRowKeys}
              selectedRows={this.state.selectedRows}
              merchants={this.state.merchants}
            />
          </Col>
          {/* order counts */}
          {this.state.loading && this.state.orders == null && <Spin></Spin>}
          {this.state.orders &&
            this.state.orders.length > 0 &&
            !this.state.orders.some(function (i) {
              return i == null;
            }) && (
              <Col span={4}>
                <Button className={"order-count-button"} type="primary" disabled>
                  orders loaded: {this.state.orders.length}
                </Button>
              </Col>
            )}
          {this.state.selectedRowKeys && this.state.selectedRowKeys.length > 0 && (
            <Col span={4}>
              <Button className={"order-count-button"} type="primary" disabled>
                orders selected: {this.state.selectedRowKeys.length}
              </Button>
            </Col>
          )}
        </Row>

        {this.state.orders &&
          this.state.orders.length > 0 &&
          !this.state.orders.some(function (i) {
            return i == null;
          }) && (
            <Row>
              <Col span={24}>
                <div className="infinite-container" style={{ height: "75vh", overflow: "auto" }}>
                  {this.state.orders && (
                    <InfiniteScroll
                      initialLoad={false}
                      loadMore={this.handleInfiniteOnLoad}
                      pageStart={1}
                      hasMore={!this.state.loading && this.state.hasMore}
                      threshold={20}
                      useWindow={false}
                    >
                      <Table
                        rowClassName={(record) => {
                          let className = "";
                          if (record.id === this.state.selectedOrder) {
                            className += "selected-order ";
                          }
                          return className;
                        }}
                        loading={this.state.loading}
                        scroll={{ x: "400" }}
                        style={{ whiteSpace: "pre" }}
                        pagination={false}
                        size={"small"}
                        columns={columns}
                        dataSource={this.state.orders}
                        rowKey={(order) => order.id}
                        expandedRowRender={(order) => {
                          return (
                            <OrderListDetails
                              key={order.id}
                              order={order}
                              isMobile={this.props.isMobile}
                              refreshOrders={this.refreshOrders}
                              refreshOrdersFromEdit={this.refreshOrdersFromEdit}
                            />
                          );
                        }}
                        expandIconAsCell={false}
                        expandIconColumnIndex={-1}
                        onRow={(record, rowIndex) => {
                          return {
                            onClick: (event) => {
                              if (event.currentTarget.dataset.rowKey === this.state.selectedOrder) {
                                this.setState({
                                  selectedOrder: null,
                                });
                              } else {
                                this.setState(
                                  {
                                    selectedOrder: event.currentTarget.dataset.rowKey,
                                  },
                                  this.scrollToSelected,
                                );
                              }
                            },
                          };
                        }}
                        expandedRowKeys={[this.state.selectedOrder]}
                        rowSelection={rowSelection}
                      />
                    </InfiniteScroll>
                  )}
                </div>
              </Col>
            </Row>
          )}
      </div>
    );
  }
}

Dashboard.propTypes = {
  user: PropTypes.string,
  roles: PropTypes.array,
  isHeavyParcelConsolidationV1: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  roles: state.auth.roles,
  isHeavyParcelConsolidationV1: state.featureFlag.heavyParcelConsolidationV1,
});

export default connect(mapStateToProps)(Dashboard);
